import Link from 'next/link'
import ErrorPlaceholder from '@fdn/error_placeholder'
import Banner from 'Components/banner/banner'

const BeautyFilesDesktopBanner = (props) => {
  return (
    <div className="beauty-files-section ads-empty">
      <div className="beauty-files-section-inner-wrapper">
        <h3 className="beauty-files-section-title">Beauty Files</h3>
        <p className="beauty-files-section-subtitle">
          Our finest signature articles, specially created for your own perusal
        </p>
        <div className="row content-row">
          <div className="col ads">
            <Banner
              name="mediumrectangle1"
              placeholder="mediumrectangle2"
              is_mobile={props.is_mobile}
              class_name="ads-medium-rectangle"
            />
          </div>
          <div className="col beauty-file">
            {Array.isArray(props.beauty_files) ? (
              props.beauty_files.length ? (
                <div className="beauty-file-wrapper">
                  {props.beauty_files.slice(0, 8).map((beauty_file, id) => (
                      <a id={`id_beauty_files_${id + 1}`} 
                      href={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/blog/tag/${beauty_file.slug}`}
                      key={id}>
                        
                        <img
                          src={beauty_file.image}
                          alt={beauty_file.name}
                        />
                      </a>
                  ))}
                </div>
              ) : (
                <ErrorPlaceholder
                  is_mobile={props.is_mobile}
                  icon="https://s3-ap-southeast-1.amazonaws.com/assets.femaledaily.com/editorial/no-data-icon.svg"
                  icon_alt="no data icon female daily"
                  title="Sorry :("
                  text="There’s no data to display"
                />
              )
            ) : (
              <ErrorPlaceholder
                is_mobile={props.is_mobile}
                icon="https://s3-ap-southeast-1.amazonaws.com/assets.femaledaily.com/editorial/something-wrong-icon.svg"
                icon_alt="wrong icon female daily"
                title="Uh Oh!"
                text="Something went wrong"
              />
            )}
          </div>
        </div>
      </div>
      <h2 className="beauty-background">Beauty</h2>
      <h1 className="files-background">FILES</h1>
      <style jsx>
        {`
          .beauty-files-section {
            background: #f9fafb;
            padding: 60px 40px 54px 40px;
            // margin: 15px 0;
            width: 100%;
            position: relative;
          }

          .beauty-files-section.ads-empty .beauty-files-section-inner-wrapper {
            max-width: 1200px;
            margin: 0 auto;
          }

          .beauty-files-section-title {
            font-family: Josefin Sans, sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            text-transform: uppercase;
            margin: 0;
          }

          .beauty-files-section-subtitle {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            color: #454f5b;
            margin: 0 0 52px 0;
          }

          .beauty-file-wrapper {
            position: relative;
            z-index: 2;
            display: flex;
            max-width: calc(100% + 16px);
            margin-left: -8px;
            margin-right: -8px;
            flex-wrap: wrap;
            // display: grid;
            // grid-template-columns: repeat(4, 1fr);
            // grid-gap: 16px;
            // justify-content: center;
          }

          .beauty-file-wrapper a {
            width: 240px;
            max-width: calc(25% - 16px);
            margin: 0 8px 16px 8px;
          }

          .beauty-file-wrapper a img {
            // margin: 0 16px 16px 0;
            box-sizing: border-box;
            width: 100%;
          }

          .beauty-file-wrapper img:hover {
            transition: 0.3s;
            opacity: 0.8;
            cursor: pointer;
          }

          .beauty-background {
            position: absolute;
            right: 290px;
            top: 22px;
            font-family: Josefin Sans, sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 120px;
            line-height: 100%;
            color: #f0f3f7;
            z-index: 0;
            margin: 0;
          }

          .files-background {
            position: absolute;
            top: 123px;
            right: -20px;
            font-family: Josefin Sans, sans-serif;
            font-style: italic;
            font-weight: bold;
            font-size: 200px;
            line-height: 100%;
            color: #f0f3f7;
            z-index: 0;
            margin: 0;
          }

          .beauty-files-section .content-row {
            flex-wrap: nowrap;
            flex-direction: row-reverse;
            justify-content: flex-end;
          }

          .col {
            z-index: 1;
          }

          .col.beauty-file {
            flex: unset;
            max-width: calc(256px * 4);
          }

          .col.ads {
            min-width: ${props.dataAds ? '300px' : '0px'};
            text-align: center;
          }

          .col.ads:empty {
            display: none;
          }

          // .col.ads:empty ~ .col.beauty-file {
          //   max-width: unset;
          // }
        `}
      </style>
    </div>
  )
}

export default BeautyFilesDesktopBanner
