export default {
  FETCH_INIT: 'fetch_init',
  FETCH_LOADING: 'fetch_loading',
  FETCH_ERROR: 'fetch_error',
  FETCH_RESULT: 'fetch_result',
  top_menu: {
    title_menu: {
      text: 'Download Female Daily App',
      link: {
        desktop: '//app.femaledaily.com',
        mobile: {
          android:
            'https://play.google.com/store/apps/details?id=com.fdbr.android',
          ios: 'https://apps.apple.com/id/app/female-daily-beauty-review/id1160742672',
        },
      },
    },
    menus: [
      {
        text: 'FEMALE DAILY',
        link: process.env.NEXT_PUBLIC_ROOT_HOME,
        id: null,
        new: false,
      },
      {
        text: 'REVIEWS',
        link: process.env.NEXT_PUBLIC_REVIEWS_DOMAIN,
        id: null,
        new: false,
      },
      {
        text: 'ARTICLES',
        link: process.env.NEXT_PUBLIC_ROOT_DOMAIN,
        id: null,
        new: false,
      },
      {
        text: 'FEMALE DAILY STUDIO',
        link: process.env.NEXT_PUBLIC_ECM_DOMAIN,
        id: null,
        new: false,
      },
      {
        text: 'TALK',
        link: process.env.NEXT_PUBLIC_TALK_DOMAIN,
        id: null,
        new: false,
      },
      {
        text: 'TRY AND REVIEW',
        link: `${process.env.NEXT_PUBLIC_ROOT_HOME}/try-and-review`,
        id: null,
        new: false,
      },
      {
				text: process.env.NEXT_PUBLIC_ALLO_STATUS === "ON" ? "ALLO EXPLORE" : null,
				id: null,
				new: false,
				image: process.env.NEXT_PUBLIC_ALLO_STATUS === "ON" ? "https://image.femaledaily.com/web-assets/allo_explore_white.svg" : null,
			},
    ],
  },
  mega_menu: [
    {
      name: 'HOME',
      url: process.env.NEXT_PUBLIC_ROOT_DOMAIN,
      slug: 'home',
      id: 'id_home_editorial',
      children: [],
    },
    {
      name: 'TOPICS',
      url: null,
      slug: 'read',
      id: 'id_home_read',
      children: [],
    },
    {
      name: 'VIDEO',
      url: '/blog/category/video-blog',
      slug: 'video-blog',
      id: 'id_home_video',
      children: [],
    },
    {
      name: 'QUIZ',
      url: '/blog/category/quiz',
      slug: 'quiz',
      id: 'id_home_quiz',
      children: [],
    },
    {
      name: 'PODCAST',
      url: '/blog/category/podcast',
      slug: 'podcast',
      id: 'id_home_podcast',
      children: [],
    },
    {
      name: 'BEAUTYPEDIA',
      url: '/blog/category/beautypedia',
      slug: 'beautypedia',
      id: 'id_home_beautypedia',
      children: [],
    },
    {
      name: 'MALE DAILY',
      url: '/maledaily',
      slug: 'maledaily',
      id: 'id_home_male_daily',
      children: [],
    },
  ],
  config_sidebar: [
    {
      name: 'FEMALE DAILY',
      value: 'home',
      pathname: process.env.NEXT_PUBLIC_ROOT_HOME,
      query: '',
      visible: true,
      new: false,
    },
    {
      name: 'REVIEWS',
      value: 'reviews',
      pathname: process.env.NEXT_PUBLIC_REVIEWS_DOMAIN,
      query: 'order=newest',
      visible: true,
      new: false,
    },
    // {
    //   name: 'EDITORIAL',
    //   value: 'editorial',
    //   pathname: process.env.NEXT_PUBLIC_ROOT_DOMAIN,
    //   query: '',
    //   visible: true,
    //   new: false,
    // },
    {
      name: 'ARTICLES',
      value: 'editorial',
      pathname: process.env.NEXT_PUBLIC_ROOT_DOMAIN,
      query: '',
      visible: true,
      new: false,
    },
    {
      name: 'FEMALE DAILY STUDIO',
      value: 'studio',
      pathname: process.env.NEXT_PUBLIC_ECM_DOMAIN,
      query: 'page=1',
      visible: true,
      new: false,
    },
    {
      name: 'TALK',
      value: 'talk',
      pathname: process.env.NEXT_PUBLIC_TALK_DOMAIN,
      query: '',
      visible: true,
      new: false,
    },
    {
      name: 'TRY AND REVIEW',
      value: 'try-and-review',
      pathname: `${process.env.NEXT_PUBLIC_ROOT_HOME}/try-and-review`,
      query: '',
      visible: true,
      new: false,
    },
  ],
  HOVER_TYPE: {
    IMAGE: 'transition: 0.3s; opacity: 0.8;',
    TEXT_PINK: 'transition: 0.3s; color: #db284e;',
    TEXT_GRAY: 'transition: 0.3s; color: #9b9b9b;',
  },
  imageBrokenDefault: {
    square: "https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/images/broken-image/broken-image-square.png",
    rectangle: "https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/images/broken-image/broken-image-rectangle.png",
    rectangle1: "https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/images/broken-image/broken-image-rectangle-1.png",    
    user: "https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/new-fdbr/default-profile.svg",
  },
  SEARCH_TAB: {
		ALL: "all",
		PRODUCT: "products",
		ARTICLE: "articles",
		// THREAD          : 'thread',
		BRAND: "brands",
		// SALON_SPA       : 'salon_&_spa',
		USER: "users",
		GROUP: "groups",
		TOPIC: "topics",
		TALK: "talks",
	},
  DATA_LAYER_REFERRAL: {
    SYNERGY_MEDIA: {
      FROM_FD: "fromsynergymediafd",
      FROM_SYNERGY_MEDIA: "fromsynergymedia",
    },
  },
}
