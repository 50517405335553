import Link from 'next/link'
import ErrorPlaceholder from '@fdn/error_placeholder'
// import Image from 'next/image'
import ImageFallback from 'Components/imagefallback'
import vars from 'Consts/vars'

const BeautyFilesDesktopBanner = (props) => {
  return (
    <div className="beauty-files-section">
      <h3 className="beauty-files-section-title">Beauty Files</h3>
      <p className="beauty-files-section-subtitle">
        Our finest signature articles, specially created for your own perusal
      </p>

      <div className="beauty-file-wrapper">
        {Array.isArray(props.beauty_files) ? (
          props.beauty_files.length ? (
            props.beauty_files.slice(0, 8).map((beauty_file, id) => (
              <Link
                key={id}
                href={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/blog/tag/${beauty_file.slug}`}
              >
                <a id={`id_beauty_files_${id + 1}`}>
                  <ImageFallback
                    src={beauty_file.image}
                    alt={beauty_file.name}
                    layout="fixed"
                    objectFit="cover"
                    width={192}
                    height={120}
                    quality={90}
                    fallbackSrc={vars.imageBrokenDefault.square}
                  />
                </a>
              </Link>
            ))
          ) : (
            <ErrorPlaceholder
              is_mobile={props.is_mobile}
              icon="https://s3-ap-southeast-1.amazonaws.com/assets.femaledaily.com/editorial/no-data-icon.svg"
              icon_alt="no data icon female daily"
              title="Sorry :("
              text="There’s no data to display"
            />
          )
        ) : (
          <ErrorPlaceholder
            is_mobile={props.is_mobile}
            icon="https://s3-ap-southeast-1.amazonaws.com/assets.femaledaily.com/editorial/something-wrong-icon.svg"
            icon_alt="wrong icon female daily"
            title="Uh Oh!"
            text="Something went wrong"
          />
        )}
      </div>
      <style>
        {`
          .beauty-files-section {
            background: #F9FAFB;
            padding: 24px 12px;
            width: calc(100% - 24px);
            position: relative;
          }


          .beauty-files-section-title {
            font-family: Josefin Sans, sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            text-transform: uppercase;
            margin: 0;
          }

          .beauty-files-section-subtitle {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            color: #454F5B;
            margin: 0 0 16px 0;
          }

          .beauty-file-wrapper {
            display: flex;
            flex-wrap: nowrap;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: -ms-autohiding-scrollbar;
            ${
              !Array.isArray(props.beauty_files) ||
              props.beauty_files.length === 0
                ? 'justify-content: center;'
                : ''
            }
          }

          .beauty-file-wrapper::-webkit-scrollbar {
            display: none;
          }

          .beauty-file-wrapper a {
            margin-right: 8px;
            width: 192px;
            min-width: 192px;
          }

      `}
      </style>
    </div>
  )
}

export default BeautyFilesDesktopBanner
