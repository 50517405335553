import React from 'react'
import BeautyFilesMobile from './beauty_files_mobile'
import BeautyFilesDesktopBanner from './beauty_files_desktop_banner'

const BeautyFiles = ({ is_mobile, beauty_files, dataAds }) => {
  const props = {
    is_mobile: is_mobile || false,
    dataAds: dataAds || false,
    beauty_files: Array.isArray(beauty_files) ? beauty_files : [],
  }

  if (props.is_mobile) {
    return <BeautyFilesMobile {...props} />
  } else {
    return <BeautyFilesDesktopBanner {...props} />
  }
}

export default BeautyFiles
